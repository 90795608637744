export const AddAddress = () => import('../../components/AddAddress.vue' /* webpackChunkName: "components/add-address" */).then(c => wrapFunctional(c.default || c))
export const AddAddressModal = () => import('../../components/AddAddressModal.vue' /* webpackChunkName: "components/add-address-modal" */).then(c => wrapFunctional(c.default || c))
export const AddPaymentMethod = () => import('../../components/AddPaymentMethod.vue' /* webpackChunkName: "components/add-payment-method" */).then(c => wrapFunctional(c.default || c))
export const AddPaymentModal = () => import('../../components/AddPaymentModal.vue' /* webpackChunkName: "components/add-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddressCheckModal = () => import('../../components/AddressCheckModal.vue' /* webpackChunkName: "components/address-check-modal" */).then(c => wrapFunctional(c.default || c))
export const AddressFind = () => import('../../components/AddressFind.vue' /* webpackChunkName: "components/address-find" */).then(c => wrapFunctional(c.default || c))
export const AddressLine = () => import('../../components/AddressLine.vue' /* webpackChunkName: "components/address-line" */).then(c => wrapFunctional(c.default || c))
export const AddressPickModal = () => import('../../components/AddressPickModal.vue' /* webpackChunkName: "components/address-pick-modal" */).then(c => wrapFunctional(c.default || c))
export const AddressPicker = () => import('../../components/AddressPicker.vue' /* webpackChunkName: "components/address-picker" */).then(c => wrapFunctional(c.default || c))
export const AddressSearch = () => import('../../components/AddressSearch.vue' /* webpackChunkName: "components/address-search" */).then(c => wrapFunctional(c.default || c))
export const AddressSheet = () => import('../../components/AddressSheet.vue' /* webpackChunkName: "components/address-sheet" */).then(c => wrapFunctional(c.default || c))
export const AppVersion = () => import('../../components/AppVersion.vue' /* webpackChunkName: "components/app-version" */).then(c => wrapFunctional(c.default || c))
export const Back = () => import('../../components/Back.vue' /* webpackChunkName: "components/back" */).then(c => wrapFunctional(c.default || c))
export const ButtonComponent = () => import('../../components/ButtonComponent.vue' /* webpackChunkName: "components/button-component" */).then(c => wrapFunctional(c.default || c))
export const ButtonRound = () => import('../../components/ButtonRound.vue' /* webpackChunkName: "components/button-round" */).then(c => wrapFunctional(c.default || c))
export const CartBar = () => import('../../components/CartBar.vue' /* webpackChunkName: "components/cart-bar" */).then(c => wrapFunctional(c.default || c))
export const CartDrawer = () => import('../../components/CartDrawer.vue' /* webpackChunkName: "components/cart-drawer" */).then(c => wrapFunctional(c.default || c))
export const CartItem = () => import('../../components/CartItem.vue' /* webpackChunkName: "components/cart-item" */).then(c => wrapFunctional(c.default || c))
export const CartItemGroup = () => import('../../components/CartItemGroup.vue' /* webpackChunkName: "components/cart-item-group" */).then(c => wrapFunctional(c.default || c))
export const CartSheet = () => import('../../components/CartSheet.vue' /* webpackChunkName: "components/cart-sheet" */).then(c => wrapFunctional(c.default || c))
export const ChangeCompanyModal = () => import('../../components/ChangeCompanyModal.vue' /* webpackChunkName: "components/change-company-modal" */).then(c => wrapFunctional(c.default || c))
export const ConceptModal = () => import('../../components/ConceptModal.vue' /* webpackChunkName: "components/concept-modal" */).then(c => wrapFunctional(c.default || c))
export const ConceptPostModal = () => import('../../components/ConceptPostModal.vue' /* webpackChunkName: "components/concept-post-modal" */).then(c => wrapFunctional(c.default || c))
export const CorporateAccountModal = () => import('../../components/CorporateAccountModal.vue' /* webpackChunkName: "components/corporate-account-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateCorporateAccountModal = () => import('../../components/CreateCorporateAccountModal.vue' /* webpackChunkName: "components/create-corporate-account-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateOrLoginModal = () => import('../../components/CreateOrLoginModal.vue' /* webpackChunkName: "components/create-or-login-modal" */).then(c => wrapFunctional(c.default || c))
export const CreateUserModal = () => import('../../components/CreateUserModal.vue' /* webpackChunkName: "components/create-user-modal" */).then(c => wrapFunctional(c.default || c))
export const DeliveryChooserModal = () => import('../../components/DeliveryChooserModal.vue' /* webpackChunkName: "components/delivery-chooser-modal" */).then(c => wrapFunctional(c.default || c))
export const DeliveryMethodToggle = () => import('../../components/DeliveryMethodToggle.vue' /* webpackChunkName: "components/delivery-method-toggle" */).then(c => wrapFunctional(c.default || c))
export const DeliveryMethodToggleNew = () => import('../../components/DeliveryMethodToggleNew.vue' /* webpackChunkName: "components/delivery-method-toggle-new" */).then(c => wrapFunctional(c.default || c))
export const DesktopWelcomeSlides = () => import('../../components/DesktopWelcomeSlides.vue' /* webpackChunkName: "components/desktop-welcome-slides" */).then(c => wrapFunctional(c.default || c))
export const DishCarousel = () => import('../../components/DishCarousel.vue' /* webpackChunkName: "components/dish-carousel" */).then(c => wrapFunctional(c.default || c))
export const DishModal = () => import('../../components/DishModal.vue' /* webpackChunkName: "components/dish-modal" */).then(c => wrapFunctional(c.default || c))
export const DishOptionGroup = () => import('../../components/DishOptionGroup.vue' /* webpackChunkName: "components/dish-option-group" */).then(c => wrapFunctional(c.default || c))
export const DishOptionGroupItem = () => import('../../components/DishOptionGroupItem.vue' /* webpackChunkName: "components/dish-option-group-item" */).then(c => wrapFunctional(c.default || c))
export const DishOptionGroups = () => import('../../components/DishOptionGroups.vue' /* webpackChunkName: "components/dish-option-groups" */).then(c => wrapFunctional(c.default || c))
export const DishSheet = () => import('../../components/DishSheet.vue' /* webpackChunkName: "components/dish-sheet" */).then(c => wrapFunctional(c.default || c))
export const DishSlider = () => import('../../components/DishSlider.vue' /* webpackChunkName: "components/dish-slider" */).then(c => wrapFunctional(c.default || c))
export const DynamicModal = () => import('../../components/DynamicModal.vue' /* webpackChunkName: "components/dynamic-modal" */).then(c => wrapFunctional(c.default || c))
export const DynamicSheet = () => import('../../components/DynamicSheet.vue' /* webpackChunkName: "components/dynamic-sheet" */).then(c => wrapFunctional(c.default || c))
export const DynamicSideDrawer = () => import('../../components/DynamicSideDrawer.vue' /* webpackChunkName: "components/dynamic-side-drawer" */).then(c => wrapFunctional(c.default || c))
export const EditAddress = () => import('../../components/EditAddress.vue' /* webpackChunkName: "components/edit-address" */).then(c => wrapFunctional(c.default || c))
export const EditAddressModal = () => import('../../components/EditAddressModal.vue' /* webpackChunkName: "components/edit-address-modal" */).then(c => wrapFunctional(c.default || c))
export const EditPaymentMethod = () => import('../../components/EditPaymentMethod.vue' /* webpackChunkName: "components/edit-payment-method" */).then(c => wrapFunctional(c.default || c))
export const EditPaymentModal = () => import('../../components/EditPaymentModal.vue' /* webpackChunkName: "components/edit-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const FakeSplash = () => import('../../components/FakeSplash.vue' /* webpackChunkName: "components/fake-splash" */).then(c => wrapFunctional(c.default || c))
export const FlexPlaceholder = () => import('../../components/FlexPlaceholder.vue' /* webpackChunkName: "components/flex-placeholder" */).then(c => wrapFunctional(c.default || c))
export const FloatingAppMenu = () => import('../../components/FloatingAppMenu.vue' /* webpackChunkName: "components/floating-app-menu" */).then(c => wrapFunctional(c.default || c))
export const FoodOptionGroup = () => import('../../components/FoodOptionGroup.vue' /* webpackChunkName: "components/food-option-group" */).then(c => wrapFunctional(c.default || c))
export const GhostImage = () => import('../../components/GhostImage.vue' /* webpackChunkName: "components/ghost-image" */).then(c => wrapFunctional(c.default || c))
export const GhostLine = () => import('../../components/GhostLine.vue' /* webpackChunkName: "components/ghost-line" */).then(c => wrapFunctional(c.default || c))
export const GroupCartModal = () => import('../../components/GroupCartModal.vue' /* webpackChunkName: "components/group-cart-modal" */).then(c => wrapFunctional(c.default || c))
export const GroupCartSelector = () => import('../../components/GroupCartSelector.vue' /* webpackChunkName: "components/group-cart-selector" */).then(c => wrapFunctional(c.default || c))
export const GroupCartSetNameModal = () => import('../../components/GroupCartSetNameModal.vue' /* webpackChunkName: "components/group-cart-set-name-modal" */).then(c => wrapFunctional(c.default || c))
export const GroupCartSheet = () => import('../../components/GroupCartSheet.vue' /* webpackChunkName: "components/group-cart-sheet" */).then(c => wrapFunctional(c.default || c))
export const HeaderComponent = () => import('../../components/HeaderComponent.vue' /* webpackChunkName: "components/header-component" */).then(c => wrapFunctional(c.default || c))
export const HowToConnectCorporateAccountModal = () => import('../../components/HowToConnectCorporateAccountModal.vue' /* webpackChunkName: "components/how-to-connect-corporate-account-modal" */).then(c => wrapFunctional(c.default || c))
export const HowToInvoiceModal = () => import('../../components/HowToInvoiceModal.vue' /* webpackChunkName: "components/how-to-invoice-modal" */).then(c => wrapFunctional(c.default || c))
export const HowToInvoiceSheet = () => import('../../components/HowToInvoiceSheet.vue' /* webpackChunkName: "components/how-to-invoice-sheet" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const ImgComponent = () => import('../../components/ImgComponent.vue' /* webpackChunkName: "components/img-component" */).then(c => wrapFunctional(c.default || c))
export const ImpactScoreOnIndex = () => import('../../components/ImpactScoreOnIndex.vue' /* webpackChunkName: "components/impact-score-on-index" */).then(c => wrapFunctional(c.default || c))
export const InputComponent = () => import('../../components/InputComponent.vue' /* webpackChunkName: "components/input-component" */).then(c => wrapFunctional(c.default || c))
export const InteractiveMap = () => import('../../components/InteractiveMap.vue' /* webpackChunkName: "components/interactive-map" */).then(c => wrapFunctional(c.default || c))
export const InvitePostModal = () => import('../../components/InvitePostModal.vue' /* webpackChunkName: "components/invite-post-modal" */).then(c => wrapFunctional(c.default || c))
export const LabelComponent = () => import('../../components/LabelComponent.vue' /* webpackChunkName: "components/label-component" */).then(c => wrapFunctional(c.default || c))
export const LineItem = () => import('../../components/LineItem.vue' /* webpackChunkName: "components/line-item" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const LoginCodeRequest = () => import('../../components/LoginCodeRequest.vue' /* webpackChunkName: "components/login-code-request" */).then(c => wrapFunctional(c.default || c))
export const LoginCodeVerify = () => import('../../components/LoginCodeVerify.vue' /* webpackChunkName: "components/login-code-verify" */).then(c => wrapFunctional(c.default || c))
export const LoginCodeVerifyModal = () => import('../../components/LoginCodeVerifyModal.vue' /* webpackChunkName: "components/login-code-verify-modal" */).then(c => wrapFunctional(c.default || c))
export const LoginModal = () => import('../../components/LoginModal.vue' /* webpackChunkName: "components/login-modal" */).then(c => wrapFunctional(c.default || c))
export const LunchCreateUserModal = () => import('../../components/LunchCreateUserModal.vue' /* webpackChunkName: "components/lunch-create-user-modal" */).then(c => wrapFunctional(c.default || c))
export const LunchEditCommentsModal = () => import('../../components/LunchEditCommentsModal.vue' /* webpackChunkName: "components/lunch-edit-comments-modal" */).then(c => wrapFunctional(c.default || c))
export const LunchEditDeliveryModal = () => import('../../components/LunchEditDeliveryModal.vue' /* webpackChunkName: "components/lunch-edit-delivery-modal" */).then(c => wrapFunctional(c.default || c))
export const MenuCategories = () => import('../../components/MenuCategories.vue' /* webpackChunkName: "components/menu-categories" */).then(c => wrapFunctional(c.default || c))
export const MenuDish = () => import('../../components/MenuDish.vue' /* webpackChunkName: "components/menu-dish" */).then(c => wrapFunctional(c.default || c))
export const MobileHeader = () => import('../../components/MobileHeader.vue' /* webpackChunkName: "components/mobile-header" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalItem = () => import('../../components/ModalItem.vue' /* webpackChunkName: "components/modal-item" */).then(c => wrapFunctional(c.default || c))
export const ModalOverlay = () => import('../../components/ModalOverlay.vue' /* webpackChunkName: "components/modal-overlay" */).then(c => wrapFunctional(c.default || c))
export const NewLoginModal = () => import('../../components/NewLoginModal.vue' /* webpackChunkName: "components/new-login-modal" */).then(c => wrapFunctional(c.default || c))
export const Order = () => import('../../components/Order.vue' /* webpackChunkName: "components/order" */).then(c => wrapFunctional(c.default || c))
export const OrderBagFee = () => import('../../components/OrderBagFee.vue' /* webpackChunkName: "components/order-bag-fee" */).then(c => wrapFunctional(c.default || c))
export const OrderBar = () => import('../../components/OrderBar.vue' /* webpackChunkName: "components/order-bar" */).then(c => wrapFunctional(c.default || c))
export const OrderConfirmation = () => import('../../components/OrderConfirmation.vue' /* webpackChunkName: "components/order-confirmation" */).then(c => wrapFunctional(c.default || c))
export const OrderDelivery = () => import('../../components/OrderDelivery.vue' /* webpackChunkName: "components/order-delivery" */).then(c => wrapFunctional(c.default || c))
export const OrderDetails = () => import('../../components/OrderDetails.vue' /* webpackChunkName: "components/order-details" */).then(c => wrapFunctional(c.default || c))
export const OrderDrawer = () => import('../../components/OrderDrawer.vue' /* webpackChunkName: "components/order-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrderModal = () => import('../../components/OrderModal.vue' /* webpackChunkName: "components/order-modal" */).then(c => wrapFunctional(c.default || c))
export const OrderServiceFee = () => import('../../components/OrderServiceFee.vue' /* webpackChunkName: "components/order-service-fee" */).then(c => wrapFunctional(c.default || c))
export const OrderSheet = () => import('../../components/OrderSheet.vue' /* webpackChunkName: "components/order-sheet" */).then(c => wrapFunctional(c.default || c))
export const OrderSubtotal = () => import('../../components/OrderSubtotal.vue' /* webpackChunkName: "components/order-subtotal" */).then(c => wrapFunctional(c.default || c))
export const OrderTotal = () => import('../../components/OrderTotal.vue' /* webpackChunkName: "components/order-total" */).then(c => wrapFunctional(c.default || c))
export const OrdersModal = () => import('../../components/OrdersModal.vue' /* webpackChunkName: "components/orders-modal" */).then(c => wrapFunctional(c.default || c))
export const OrdersSheet = () => import('../../components/OrdersSheet.vue' /* webpackChunkName: "components/orders-sheet" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodLine = () => import('../../components/PaymentMethodLine.vue' /* webpackChunkName: "components/payment-method-line" */).then(c => wrapFunctional(c.default || c))
export const PaymentSheet = () => import('../../components/PaymentSheet.vue' /* webpackChunkName: "components/payment-sheet" */).then(c => wrapFunctional(c.default || c))
export const RequestHappyLunchOfferModal = () => import('../../components/RequestHappyLunchOfferModal.vue' /* webpackChunkName: "components/request-happy-lunch-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const Restaurant = () => import('../../components/Restaurant.vue' /* webpackChunkName: "components/restaurant" */).then(c => wrapFunctional(c.default || c))
export const RestaurantCategories = () => import('../../components/RestaurantCategories.vue' /* webpackChunkName: "components/restaurant-categories" */).then(c => wrapFunctional(c.default || c))
export const RestaurantCategoriesCarousel = () => import('../../components/RestaurantCategoriesCarousel.vue' /* webpackChunkName: "components/restaurant-categories-carousel" */).then(c => wrapFunctional(c.default || c))
export const RestaurantCategoriesGhost = () => import('../../components/RestaurantCategoriesGhost.vue' /* webpackChunkName: "components/restaurant-categories-ghost" */).then(c => wrapFunctional(c.default || c))
export const RestaurantCategory = () => import('../../components/RestaurantCategory.vue' /* webpackChunkName: "components/restaurant-category" */).then(c => wrapFunctional(c.default || c))
export const RestaurantGhost = () => import('../../components/RestaurantGhost.vue' /* webpackChunkName: "components/restaurant-ghost" */).then(c => wrapFunctional(c.default || c))
export const RestaurantHero = () => import('../../components/RestaurantHero.vue' /* webpackChunkName: "components/restaurant-hero" */).then(c => wrapFunctional(c.default || c))
export const RestaurantLocation = () => import('../../components/RestaurantLocation.vue' /* webpackChunkName: "components/restaurant-location" */).then(c => wrapFunctional(c.default || c))
export const RestaurantLocationCard = () => import('../../components/RestaurantLocationCard.vue' /* webpackChunkName: "components/restaurant-location-card" */).then(c => wrapFunctional(c.default || c))
export const RestaurantLocationPicker = () => import('../../components/RestaurantLocationPicker.vue' /* webpackChunkName: "components/restaurant-location-picker" */).then(c => wrapFunctional(c.default || c))
export const RestaurantLocationPickerModal = () => import('../../components/RestaurantLocationPickerModal.vue' /* webpackChunkName: "components/restaurant-location-picker-modal" */).then(c => wrapFunctional(c.default || c))
export const RestaurantLocationSheet = () => import('../../components/RestaurantLocationSheet.vue' /* webpackChunkName: "components/restaurant-location-sheet" */).then(c => wrapFunctional(c.default || c))
export const RestaurantMenu = () => import('../../components/RestaurantMenu.vue' /* webpackChunkName: "components/restaurant-menu" */).then(c => wrapFunctional(c.default || c))
export const RestaurantSharedMealsBanner = () => import('../../components/RestaurantSharedMealsBanner.vue' /* webpackChunkName: "components/restaurant-shared-meals-banner" */).then(c => wrapFunctional(c.default || c))
export const Restaurants = () => import('../../components/Restaurants.vue' /* webpackChunkName: "components/restaurants" */).then(c => wrapFunctional(c.default || c))
export const RestaurantsGhost = () => import('../../components/RestaurantsGhost.vue' /* webpackChunkName: "components/restaurants-ghost" */).then(c => wrapFunctional(c.default || c))
export const SendReceipt = () => import('../../components/SendReceipt.vue' /* webpackChunkName: "components/send-receipt" */).then(c => wrapFunctional(c.default || c))
export const SharedMealsConceptBanner = () => import('../../components/SharedMealsConceptBanner.vue' /* webpackChunkName: "components/shared-meals-concept-banner" */).then(c => wrapFunctional(c.default || c))
export const SharedMealsFloater = () => import('../../components/SharedMealsFloater.vue' /* webpackChunkName: "components/shared-meals-floater" */).then(c => wrapFunctional(c.default || c))
export const Sheet = () => import('../../components/Sheet.vue' /* webpackChunkName: "components/sheet" */).then(c => wrapFunctional(c.default || c))
export const SideDrawer = () => import('../../components/SideDrawer.vue' /* webpackChunkName: "components/side-drawer" */).then(c => wrapFunctional(c.default || c))
export const SingleOrderModal = () => import('../../components/SingleOrderModal.vue' /* webpackChunkName: "components/single-order-modal" */).then(c => wrapFunctional(c.default || c))
export const StatusBar = () => import('../../components/StatusBar.vue' /* webpackChunkName: "components/status-bar" */).then(c => wrapFunctional(c.default || c))
export const StepContent = () => import('../../components/StepContent.vue' /* webpackChunkName: "components/step-content" */).then(c => wrapFunctional(c.default || c))
export const StepContentTransition = () => import('../../components/StepContentTransition.vue' /* webpackChunkName: "components/step-content-transition" */).then(c => wrapFunctional(c.default || c))
export const StepHeader = () => import('../../components/StepHeader.vue' /* webpackChunkName: "components/step-header" */).then(c => wrapFunctional(c.default || c))
export const StepHeaderTransition = () => import('../../components/StepHeaderTransition.vue' /* webpackChunkName: "components/step-header-transition" */).then(c => wrapFunctional(c.default || c))
export const SupplierChain = () => import('../../components/SupplierChain.vue' /* webpackChunkName: "components/supplier-chain" */).then(c => wrapFunctional(c.default || c))
export const SvgInline = () => import('../../components/SvgInline.vue' /* webpackChunkName: "components/svg-inline" */).then(c => wrapFunctional(c.default || c))
export const SwipeButton = () => import('../../components/SwipeButton.vue' /* webpackChunkName: "components/swipe-button" */).then(c => wrapFunctional(c.default || c))
export const Swiper = () => import('../../components/Swiper.vue' /* webpackChunkName: "components/swiper" */).then(c => wrapFunctional(c.default || c))
export const TakeawayOverview = () => import('../../components/TakeawayOverview.vue' /* webpackChunkName: "components/takeaway-overview" */).then(c => wrapFunctional(c.default || c))
export const TermsModal = () => import('../../components/TermsModal.vue' /* webpackChunkName: "components/terms-modal" */).then(c => wrapFunctional(c.default || c))
export const TermsSheet = () => import('../../components/TermsSheet.vue' /* webpackChunkName: "components/terms-sheet" */).then(c => wrapFunctional(c.default || c))
export const TimeModal = () => import('../../components/TimeModal.vue' /* webpackChunkName: "components/time-modal" */).then(c => wrapFunctional(c.default || c))
export const TimeSheet = () => import('../../components/TimeSheet.vue' /* webpackChunkName: "components/time-sheet" */).then(c => wrapFunctional(c.default || c))
export const Toast = () => import('../../components/Toast.vue' /* webpackChunkName: "components/toast" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const TopNavigation = () => import('../../components/TopNavigation.vue' /* webpackChunkName: "components/top-navigation" */).then(c => wrapFunctional(c.default || c))
export const UserAddressModal = () => import('../../components/UserAddressModal.vue' /* webpackChunkName: "components/user-address-modal" */).then(c => wrapFunctional(c.default || c))
export const UserAddressSheet = () => import('../../components/UserAddressSheet.vue' /* webpackChunkName: "components/user-address-sheet" */).then(c => wrapFunctional(c.default || c))
export const UserEmailModal = () => import('../../components/UserEmailModal.vue' /* webpackChunkName: "components/user-email-modal" */).then(c => wrapFunctional(c.default || c))
export const UserEmailSheet = () => import('../../components/UserEmailSheet.vue' /* webpackChunkName: "components/user-email-sheet" */).then(c => wrapFunctional(c.default || c))
export const UserLocationPicker = () => import('../../components/UserLocationPicker.vue' /* webpackChunkName: "components/user-location-picker" */).then(c => wrapFunctional(c.default || c))
export const UserModal = () => import('../../components/UserModal.vue' /* webpackChunkName: "components/user-modal" */).then(c => wrapFunctional(c.default || c))
export const UserNameModal = () => import('../../components/UserNameModal.vue' /* webpackChunkName: "components/user-name-modal" */).then(c => wrapFunctional(c.default || c))
export const UserNameSheet = () => import('../../components/UserNameSheet.vue' /* webpackChunkName: "components/user-name-sheet" */).then(c => wrapFunctional(c.default || c))
export const UserPaymentModal = () => import('../../components/UserPaymentModal.vue' /* webpackChunkName: "components/user-payment-modal" */).then(c => wrapFunctional(c.default || c))
export const UserPaymentSheet = () => import('../../components/UserPaymentSheet.vue' /* webpackChunkName: "components/user-payment-sheet" */).then(c => wrapFunctional(c.default || c))
export const UserVatPreferenceModal = () => import('../../components/UserVatPreferenceModal.vue' /* webpackChunkName: "components/user-vat-preference-modal" */).then(c => wrapFunctional(c.default || c))
export const WalletModal = () => import('../../components/WalletModal.vue' /* webpackChunkName: "components/wallet-modal" */).then(c => wrapFunctional(c.default || c))
export const WelcomeBox = () => import('../../components/WelcomeBox.vue' /* webpackChunkName: "components/welcome-box" */).then(c => wrapFunctional(c.default || c))
export const WelcomeSlider = () => import('../../components/WelcomeSlider.vue' /* webpackChunkName: "components/welcome-slider" */).then(c => wrapFunctional(c.default || c))
export const WhitelabelFooter = () => import('../../components/WhitelabelFooter.vue' /* webpackChunkName: "components/whitelabel-footer" */).then(c => wrapFunctional(c.default || c))
export const WhitelabelTopbar = () => import('../../components/WhitelabelTopbar.vue' /* webpackChunkName: "components/whitelabel-topbar" */).then(c => wrapFunctional(c.default || c))
export const GroupCartNameSheet = () => import('../../components/groupCartNameSheet.vue' /* webpackChunkName: "components/group-cart-name-sheet" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
