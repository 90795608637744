import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1b8495c6 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _1d71ae44 = () => interopDefault(import('../pages/happy-breakfast.vue' /* webpackChunkName: "pages/happy-breakfast" */))
const _7d7727d2 = () => interopDefault(import('../pages/happy-fruit.vue' /* webpackChunkName: "pages/happy-fruit" */))
const _155e7fa6 = () => interopDefault(import('../pages/happy-lunch.vue' /* webpackChunkName: "pages/happy-lunch" */))
const _230c78e5 = () => interopDefault(import('../pages/happy-meetings.vue' /* webpackChunkName: "pages/happy-meetings" */))
const _a14c7f94 = () => interopDefault(import('../pages/happy-moments.vue' /* webpackChunkName: "pages/happy-moments" */))
const _bb9cfe7c = () => interopDefault(import('../pages/happy-takeaway.vue' /* webpackChunkName: "pages/happy-takeaway" */))
const _4e9df4ce = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _97fc9b7a = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _53484436 = () => interopDefault(import('../pages/orders.vue' /* webpackChunkName: "pages/orders" */))
const _42a70d17 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5bb65128 = () => interopDefault(import('../pages/tak-for-din-henvendelse.vue' /* webpackChunkName: "pages/tak-for-din-henvendelse" */))
const _f287c46a = () => interopDefault(import('../pages/user.vue' /* webpackChunkName: "pages/user" */))
const _ae99d648 = () => interopDefault(import('../pages/posts/concept.vue' /* webpackChunkName: "pages/posts/concept" */))
const _a0a22476 = () => interopDefault(import('../pages/posts/invite.vue' /* webpackChunkName: "pages/posts/invite" */))
const _18f82ea8 = () => interopDefault(import('../pages/user-settings/addresses.vue' /* webpackChunkName: "pages/user-settings/addresses" */))
const _49fda1eb = () => interopDefault(import('../pages/user-settings/corporate.vue' /* webpackChunkName: "pages/user-settings/corporate" */))
const _1a48b613 = () => interopDefault(import('../pages/user-settings/coupons.vue' /* webpackChunkName: "pages/user-settings/coupons" */))
const _1c80bf62 = () => interopDefault(import('../pages/user-settings/orders.vue' /* webpackChunkName: "pages/user-settings/orders" */))
const _565f968c = () => interopDefault(import('../pages/user-settings/payment.vue' /* webpackChunkName: "pages/user-settings/payment" */))
const _867136a2 = () => interopDefault(import('../pages/user-settings/profile.vue' /* webpackChunkName: "pages/user-settings/profile" */))
const _9f26fa5e = () => interopDefault(import('../pages/user-settings/sharedmeals.vue' /* webpackChunkName: "pages/user-settings/sharedmeals" */))
const _43d7d7d5 = () => interopDefault(import('../pages/user-settings/support.vue' /* webpackChunkName: "pages/user-settings/support" */))
const _464c690d = () => interopDefault(import('../pages/user-settings/terms.vue' /* webpackChunkName: "pages/user-settings/terms" */))
const _0f096282 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _9b255a6e = () => interopDefault(import('../pages/order/_uuid.vue' /* webpackChunkName: "pages/order/_uuid" */))
const _553539eb = () => interopDefault(import('../pages/payment-verify/_uuid.vue' /* webpackChunkName: "pages/payment-verify/_uuid" */))
const _558ca9b8 = () => interopDefault(import('../pages/restaurant/_slug.vue' /* webpackChunkName: "pages/restaurant/_slug" */))
const _50fe638a = () => interopDefault(import('../pages/supplier-chain/_slug.vue' /* webpackChunkName: "pages/supplier-chain/_slug" */))
const _a39068f2 = () => interopDefault(import('../pages/supplier/_slug.vue' /* webpackChunkName: "pages/supplier/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _1b8495c6,
    name: "checkout"
  }, {
    path: "/happy-breakfast",
    component: _1d71ae44,
    name: "happy-breakfast"
  }, {
    path: "/happy-fruit",
    component: _7d7727d2,
    name: "happy-fruit"
  }, {
    path: "/happy-lunch",
    component: _155e7fa6,
    name: "happy-lunch"
  }, {
    path: "/happy-meetings",
    component: _230c78e5,
    name: "happy-meetings"
  }, {
    path: "/happy-moments",
    component: _a14c7f94,
    name: "happy-moments"
  }, {
    path: "/happy-takeaway",
    component: _bb9cfe7c,
    name: "happy-takeaway"
  }, {
    path: "/login",
    component: _4e9df4ce,
    name: "login"
  }, {
    path: "/maintenance",
    component: _97fc9b7a,
    name: "maintenance"
  }, {
    path: "/orders",
    component: _53484436,
    name: "orders"
  }, {
    path: "/privacy-policy",
    component: _42a70d17,
    name: "privacy-policy"
  }, {
    path: "/tak-for-din-henvendelse",
    component: _5bb65128,
    name: "tak-for-din-henvendelse"
  }, {
    path: "/user",
    component: _f287c46a,
    name: "user"
  }, {
    path: "/posts/concept",
    component: _ae99d648,
    name: "posts-concept"
  }, {
    path: "/posts/invite",
    component: _a0a22476,
    name: "posts-invite"
  }, {
    path: "/user-settings/addresses",
    component: _18f82ea8,
    name: "user-settings-addresses"
  }, {
    path: "/user-settings/corporate",
    component: _49fda1eb,
    name: "user-settings-corporate"
  }, {
    path: "/user-settings/coupons",
    component: _1a48b613,
    name: "user-settings-coupons"
  }, {
    path: "/user-settings/orders",
    component: _1c80bf62,
    name: "user-settings-orders"
  }, {
    path: "/user-settings/payment",
    component: _565f968c,
    name: "user-settings-payment"
  }, {
    path: "/user-settings/profile",
    component: _867136a2,
    name: "user-settings-profile"
  }, {
    path: "/user-settings/sharedmeals",
    component: _9f26fa5e,
    name: "user-settings-sharedmeals"
  }, {
    path: "/user-settings/support",
    component: _43d7d7d5,
    name: "user-settings-support"
  }, {
    path: "/user-settings/terms",
    component: _464c690d,
    name: "user-settings-terms"
  }, {
    path: "/",
    component: _0f096282,
    name: "index"
  }, {
    path: "/order/:uuid?",
    component: _9b255a6e,
    name: "order-uuid"
  }, {
    path: "/payment-verify/:uuid?",
    component: _553539eb,
    name: "payment-verify-uuid"
  }, {
    path: "/restaurant/:slug?",
    component: _558ca9b8,
    name: "restaurant-slug"
  }, {
    path: "/supplier-chain/:slug?",
    component: _50fe638a,
    name: "supplier-chain-slug"
  }, {
    path: "/supplier/:slug?",
    component: _a39068f2,
    name: "supplier-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
